import { Fragment, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled, { css } from 'styled-components';
// components
import SideNavigation from 'components/molecules/common/SideNavigation';
import VerticalScrollLayout from 'cds/layout/VerticalScroll';
import FillButton from 'cds/buttons/Fill';
import TextButton from 'cds/buttons/Text';
import Icon from 'cds/icons';
// slices
import { getEventList } from 'slices/event.thunk';
import { getCategoryList } from 'slices/category.thunk';
// hooks
import { useSelector, useDispatch } from 'hooks/common/useStore';
// utils
import { isExistWindow } from 'lib/utils/helper';
import deviceChecker from 'lib/utils/deviceChecker';
// styles
import * as typography from 'cds/typography';
import { palette, mediaQuery, zIndex, onlyHover } from 'cds/styles';
import { setClickedItems } from 'slices/dataMining';
import { getCompulsory, getUserCompany } from 'slices/user.thunk';
import { companyConvert_Sidebar } from 'lib/utils/companyConvert';
import request from 'api';

/**
 * 사이드바
 */
const SideBar = () => {
  const router = useRouter();

  const dispatch = useDispatch();

  const categoryList = useSelector(state => state.category.categoryList);
  const classList = useSelector(state => state.user.classList.registered);
  const eventList = useSelector(state => state.event.eventList);
  const userInfo = useSelector(state => state.user.info);
  const isYGUser = userInfo.email?.includes('@ygmail.net');

  const [isIpadApp, setIsIpadApp] = useState(false);
  const [isExistUser, setIsExistUser] = useState(false);
  const [isFold, setIsFold] = useState(false);
  const foldTarget = useRef(null);

  const categoryNameList = categoryList.map(({ category }) => category);

  const onLogin = () => {
    const loginRedirectUrl = isExistWindow() ? window.location.pathname : '';
    router.push(`/user?url=${loginRedirectUrl}`);
  };

  const onToggleRegisteredClassList = () => {
    if (!isExistUser) {
      onLogin();
      return;
    }
    setIsFold(v => !v);
  };

  // 고객센터 연결 - 카톡 플러스 친구 챗
  const onCustomerCenter = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat/138067`;
  };

  // ipadApp check
  useEffect(() => {
    const device = deviceChecker();
    setIsIpadApp(device.iPadApp());
  }, []);

  useEffect(() => {
    const target = foldTarget.current;
    if (!target) {
      return;
    }

    if (isFold) {
      target.style.maxHeight = '0px';
    } else {
      target.style.maxHeight = '';
    }
  }, [isFold, foldTarget]);

  /** 기획전 리스트 호출 */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    dispatch(getEventList());
  }, [dispatch, router.isReady]);

  /** 카테고리 호출 */
  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (isIpadApp) {
      return;
    }

    if (categoryList.length) {
      return;
    }

    dispatch(getCategoryList());
  }, [dispatch, categoryList, router.isReady, isIpadApp]);

  useEffect(() => setIsExistUser(userInfo.isLoaded && userInfo.id), [userInfo]);

  const EventButtonList = eventList.map(event => {
    const url = event.address.replace(
      /https:\/\/www.classu.co.kr/gi,
      process.env.NEXT_PUBLIC_DEFAULT_HOST,
    );

    if (isIpadApp == false) {
      return (
        <Link key={event.title} href={url} passHref>
          <LinkItem>
            <Cover src={event.icon} />

            <span>{event.title}</span>
          </LinkItem>
        </Link>
      );
    }

    const ignore =
      event.address.indexOf('/event') > -1 ||
      event.address.indexOf('/bookmark') > -1 ||
      event.address.indexOf('/awards') > -1 ||
      event.address.indexOf('/external') > -1;

    if (ignore) {
      return <Fragment key={event.title}></Fragment>;
    }

    return (
      <Link
        key={event.title}
        href={event.address.replace(
          /https:\/\/www.classu.co.kr/gi,
          process.env.NEXT_PUBLIC_DEFAULT_HOST,
        )}
        passHref
      >
        <LinkItem>
          <Cover src={event.icon} />

          <span>{event.title}</span>
        </LinkItem>
      </Link>
    );
  });

  const ClassList = classList.map(cl => {
    const host = process.env.NEXT_PUBLIC_DEFAULT_HOST;
    const hrefLink =
      cl.community.open === 'Y'
        ? `${host}/community/${cl.community.id}`
        : cl.class_study_status === 'Y'
        ? `${host}/class/lecture/${cl.class_id}`
        : `${host}/class/wait/${cl.class_id}`;

    return (
      <Link key={cl.class_id} href={hrefLink} passHref>
        <LinkItem color={palette.grey080}>
          <Cover src={cl.photo} />
          <span>{cl.class_name}</span>
        </LinkItem>
      </Link>
    );
  });

  const onClickContentLayout = e => {
    dispatch(setClickedItems({ innerText: e.target.textContent, elementType: 'link' }));
  };

  const isEnrollCompulsory = useSelector(state => state.user.compulsory.enrolled);
  const userCompany = useSelector(state => state.user.company)?.toLowerCase();

  useEffect(() => {
    if (router.pathname.split('/').includes('signin')) {
      return;
    }
    if (userInfo && userInfo.id) {
      dispatch(getCompulsory({ userId: userInfo.id }, { dispatch }));
      dispatch(getUserCompany({ userId: userInfo.id }, { dispatch }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, userInfo, userInfo.id]);
  const [hasCompanyPublicLectures, setHasCompanyPublicLectures] = useState(false);
  useEffect(() => {
    if (!userCompany) {
      return;
    }
    if (!userCompany.length) {
      return;
    }
    const company = userCompany;
    request.get(`/enfit1/api/v3/biz/deal/list?company=${company}`).then(({ deal_id_list }) => {
      if (deal_id_list) {
        setHasCompanyPublicLectures(true);
      }
    });
  }, [userCompany]);
  return (
    <Layout>
      <Inner>
        <ContentLayout onClick={onClickContentLayout}>
          {!isIpadApp && router.isReady && (
            <SideTop>
              <CategoryButton size="Small" color={palette.Primary.Active}>
                <div>
                  <Icon name="ic_category" width={24} height={24} fill={palette.white} />
                  <span>카테고리</span>
                </div>
                <Icon name="ic_arrow_right_r" width={16} height={16} fill={palette.white} />
                <SideNavigation categoryList={categoryNameList} />
              </CategoryButton>
            </SideTop>
          )}

          <SideBottom isIpadApp={isIpadApp}>
            <EventListBox>
              {!!eventList.length && (
                <>
                  {EventButtonList}
                  {hasCompanyPublicLectures && (
                    <Link
                      href={`/event/${userCompany}`.replace(
                        /https:\/\/www.classu.co.kr/gi,
                        process.env.NEXT_PUBLIC_DEFAULT_HOST,
                      )}
                      passHref
                    >
                      <LinkItem>
                        <Cover src={`https://file.classu.co.kr/biz/${userCompany}-logo.png`} />
                        <span>{companyConvert_Sidebar(userCompany)}</span>
                      </LinkItem>
                    </Link>
                  )}
                  {isEnrollCompulsory && (
                    <Link
                      href={'/event/plan/compulsory'.replace(
                        /https:\/\/www.classu.co.kr/gi,
                        process.env.NEXT_PUBLIC_DEFAULT_HOST,
                      )}
                      passHref
                    >
                      <LinkItem>
                        <Cover src="https://file.classu.co.kr/biz/icon.png" />
                        <span>법정의무교육</span>
                      </LinkItem>
                    </Link>
                  )}

                  <Link href={`/community`} passHref>
                    <LinkItem>
                      <Icon name="dark_community" width={20} height={14} fill={palette.white} />
                      <span style={{ marginLeft: '20px' }}>커뮤니티</span>
                    </LinkItem>
                  </Link>
                </>
              )}
            </EventListBox>

            <Divider />

            <>
              <DropDownButton
                size="Small"
                color={palette.grey080}
                isFold={isFold}
                onClick={onToggleRegisteredClassList}
              >
                <span>수강 중인 클래스</span>
                {!!userInfo.id && (
                  <Icon name="ic_arrow_down_m" width={16} height={16} fill={palette.grey060} />
                )}
              </DropDownButton>

              {userInfo.id && !!classList.length && (
                <ClassListBox ref={foldTarget}>{ClassList}</ClassListBox>
              )}

              {userInfo.id && !classList.length && (
                <EmptyClassListBox>
                  <p>수강 중인 클래스가 없어요</p>
                  <Link href="/search?keyword=" passHref>
                    <FillButton as="a">클래스 탐색하기</FillButton>
                  </Link>
                </EmptyClassListBox>
              )}
              <Divider />
            </>

            <Link href={`/biz`} passHref>
              <IconLink>
                <Icon name="biz-icon" width={20} height={20} fill={palette.white} />
                <span>기업교육</span>
              </IconLink>
            </Link>
            <IconButton color={palette.grey080} onClick={onCustomerCenter}>
              <Icon name="ic_customer_center" width={20} height={20} stroke={palette.grey50} />
              <span>고객센터</span>
            </IconButton>
          </SideBottom>
        </ContentLayout>
      </Inner>
    </Layout>
  );
};

const Layout = styled.div`
  display: none;
  position: fixed;
  top: 84px;
  left: 0;
  width: 240px;
  max-width: 100%;
  z-index: ${zIndex.header};
  box-sizing: content-box;

  ${mediaQuery.large} {
    display: flex;
    flex-direction: column;
  }
  * {
    color: ${palette.grey50};
  }
`;

const Inner = styled.div`
  overflow-y: auto;
  height: calc(100vh - 84px);
  background-color: ${palette.system.BNB};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background-color: transparent;
  }
`;

const ContentLayout = styled(VerticalScrollLayout)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SideTop = styled.div`
  position: fixed;
  width: 240px;
  left: 0;
  padding: 12px 8px 8px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: -4px;
    right: 0;
    /* background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)); */
  }
`;

const CategoryButton = styled(FillButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding: 8px 24px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;

    & > span {
      margin-left: 16px;
      font-size: 15px;
      font-weight: 700;
      color: ${palette.white};
    }
  }

  & > nav {
    display: none;
  }

  &:hover {
    &::before {
      background-color: rgba(255, 255, 255, 0.2);
    }

    & > nav {
      display: flex;
    }
  }
`;

const SideBottom = styled.div`
  ${({ isIpadApp }) => (isIpadApp ? `padding: 8px;` : `padding: 60px 8px 8px;`)}
`;

const EventListBox = styled.div`
  color: ${palette.grey080};

  & span {
    font-size: 15px;
    font-weight: 500;
  }
`;

const ClassListBox = styled.div`
  overflow: hidden;

  & span {
    overflow: hidden;
    display: -webkit-box;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    ${typography.body3_regular};
    text-align: left;

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 50px;
      height: calc(14px * 1.42);
      top: auto;
      right: 0;
      bottom: 0;
      border-radius: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
    }

    @supports (-webkit-line-clamp: 2) {
      &::after {
        display: none;
      }
    }
  }
`;

const EmptyClassListBox = styled.div`
  padding: 8px 24px;

  p {
    margin-bottom: 16px;
    color: ${palette.grey040};
    ${typography.caption2_regular};
  }

  a {
    padding: 8px 0;
    color: ${palette.grey060};
    ${typography.caption2_medium};
    background-color: ${palette.grey010};
    text-align: center;
  }
`;

const LinkItem = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 52px;
  flex-grow: 0;
  flex-shrink: 1;
  padding: 8px 24px;
  background-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.1s ease;
  }

  & span {
    position: relative;

    &::after {
      display: ${({ selected }) => (selected ? 'block' : 'none')};
      content: '';
      position: absolute;
      top: 0px;
      right: -6px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: ${palette.red040};
    }
  }

  ${onlyHover} {
    &:hover {
      ${({ disabled }) =>
        !disabled &&
        css`
          &::before {
            background-color: rgba(0, 0, 0, 0.04);
          }
        `};
    }
  }

  &:active {
    ${({ disabled }) =>
      !disabled &&
      css`
        &::before {
          background-color: rgba(0, 0, 0, 0.04);
        }
      `};
  }
`;

const IconLink = styled(LinkItem)`
  justify-content: flex-start;
  flex: 0 auto;
  width: 100%;
  padding: 8px 24px;

  & > svg {
    margin-right: 16px;
  }

  & > span {
    ${typography.body3_regular};

    ${mediaQuery.large} {
      font-size: 14px;
    }
  }
`;

const Cover = styled.div.attrs(({ src }) => ({
  style: {
    backgroundImage: `url(${src})`,
  },
}))`
  overflow: hidden;
  position: relative;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;
`;

const IconButton = styled(TextButton)`
  justify-content: flex-start;
  flex: 0 auto;
  width: 100%;
  padding: 8px 24px;
  background-color: transparent;

  & > svg {
    margin-right: 16px;
  }

  & > span {
    ${typography.body3_regular};

    ${mediaQuery.large} {
      font-size: 14px;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  margin: 12px 8px;
  background-color: ${palette.system.grey};
`;

const DropDownButton = styled(TextButton)`
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 8px 24px;
  margin: 8px 0;
  background-color: transparent;

  & > span {
    font-size: 14px;
    font-weight: 700;
    color: ${palette.grey50};
  }

  & > svg {
    transition: transform 0.2s ease;
    transform: ${({ isFold }) => (isFold ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

export default SideBar;
