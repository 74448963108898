/** @param {string} company */
export const companyConvert = company => {
  if (company === 'yg') {
    return 'YG 임직원 전용관';
  }
  if (company === 'jw') {
    return 'JW Adv';
  }
  if (company) {
    const companyUpper = company.toUpperCase();
    return `${companyUpper} 임직원 전용관`;
  }
  return '임직원 전용관';
};

/** @param {string} company */
export const companyConvert_Sidebar = company => {
  if (company === 'yg') {
    return 'YG 임직원 전용관';
  }
  if (company === 'jw') {
    return 'JW Adv';
  }
  return '임직원 전용관';
};
